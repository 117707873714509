import { createAppTableSaga, createPersist } from 'components/AppTable/store';
import storeTableTaskFactory from 'modules/project/store/storeTableTaskFactory';

export const dashboardTaskSlice = storeTableTaskFactory({
  name: 'dashboardTasks' as const,
  onlyTable: true,
  initialState: {
    params: {
      sort: {
        column: 'task_end_date',
        order: 'ascend',
      },
    },
    paginationIncremental: true,
  },
});

export const dashboardTasksSaga = createAppTableSaga(dashboardTaskSlice);

export const dashboardTasksReducer = createPersist(dashboardTaskSlice, []);
