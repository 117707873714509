import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import ActionBarLayout from 'components/ActionbarLayout';
import environment from 'config/environment';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { getAllRoutes } from './components/Sidebar/config';
import useCountUnreadInbox from './useCountUnreadInbox';
import style from './layout.module.less';

interface AppLayoutProps {}

const Index: FC<AppLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const pathConfig = getAllRoutes();
  const activeRoute = pathConfig.find((el) => el.path && (el.path === '/' ? pathname === '/' : pathname.includes(el.path)));

  useCountUnreadInbox();

  return (
    <Layout className={style.layout}>
      <Header activeRoute={activeRoute} />

      <Helmet>
        <meta charSet="utf-8" />

        <title>
          {environment.isDevelopment ? 'Dev - ' : ''}Lionshare - {activeRoute?.label ?? 'App'}
        </title>

        <link rel="canonical" href={activeRoute?.path ?? '/'} />
      </Helmet>

      <Layout>
        <Sidebar activeRoute={activeRoute} />

        <ActionBarLayout>{children}</ActionBarLayout>
      </Layout>
    </Layout>
  );
};

export default Index;
