import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import TasksDropdown from 'components/TasksDropdown';
import BellNotification from 'components/BellNotification';
import SmsSignUpModal from 'components/SmsSignUpModal';
import TimerDropdown from 'components/Timer/TimerDropdown';
import appRoutes from 'config/appRoutes';
import ENVIRONMENT from 'config/environment';
import useHeader from './useHeader';
import GlobalSearch from '../GlobalSearch';
import ActionDropdown from '../ActionDropdown';
import UserDropdown from '../UserDropdown';
import WeeklyTimer from '../WeeklyTimer';
import { MenuItemBaseLinkType } from '../Sidebar/config';
import style from './style.module.less';

const { Header: AntHeader } = Layout;

interface HeaderProps {
  activeRoute?: MenuItemBaseLinkType;
}

const Index: FC<HeaderProps> = ({ activeRoute }) => {
  const { user } = useHeader();
  const { newUpdate } = useSelector((globalState: any) => globalState.versionUpdate);
  const [hideDropdown, setHideDropdown] = useState(false);

  return (
    <>
      {user.preferences.sms_notifications === null ? <SmsSignUpModal user={user} /> : undefined}

      {newUpdate && (
        <div className={style.refreshMessage}>
          A new update is available. Please{' '}
          <span className="primary pointer" onClick={() => window.location.reload()}>
            refresh
          </span>{' '}
          your browser.
        </div>
      )}
      <AntHeader className={`${style.header} ${ENVIRONMENT.IS_DEV_ENV ? style.dev : ''} layout-header`}>
        <div className={style.headerWrapper}>
          <Link to={appRoutes.DASHBOARD} className={style.logo}>
            <span className={style.full}>lionshare</span>
            <span className={style.short}>ls</span>
          </Link>

          <div className={style.menuItems}>
            <div className={style.hMobile} style={{ flex: '1 1 auto', maxWidth: 650 }}>
              <GlobalSearch />
            </div>

            <div className={style.hMobile}>
              <WeeklyTimer />
            </div>

            <TimerDropdown needsAlert globalActiveTimer />

            <div style={{ display: hideDropdown ? 'none' : undefined }} className={style.hMobile}>
              <TasksDropdown onEmpty={setHideDropdown} />
            </div>

            <ActionDropdown />

            <BellNotification />

            <UserDropdown user={user} activeRoute={activeRoute} />
          </div>
        </div>
      </AntHeader>
    </>
  );
};
export default Index;
